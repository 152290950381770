import React from "react";

import "../../styles/introduce.scss";

const Introduce = () => {
  return (
    <section className="introduce" id="motivo">
      <h1>Por qual motivo?</h1>

      <div className="introduce__video">
        <iframe
          id="ytplayer"
          title="faceponto"
          type="text/html"
          width="720"
          height="405"
          src="https://www.youtube.com/embed/qaZx4qVFLRo?controls=0&color=white"
          frameborder="0"
          allowfullscreen
        />
      </div>

      <div className="introduce__container">
        <div className="row">
          <div>
            <img src="assets/covid.svg" alt="covid" />
          </div>
          <div className="introduce__covid">
            <h3>COVID-19</h3>
            <p>
              O coronavírus (COVID-19) é uma doença infecciosa causada por um
              novo vírus que nunca havia sido identificado em humanos. O vírus
              causa uma doença respiratória semelhante à gripe e tem sintomas
              como tosse, febre e, em casos mais graves, pneumonia. É possível
              se proteger ao lavar as mãos com frequência e evitar tocar no
              rosto.
            </p>
          </div>
        </div>
        <div>
          Considerando a grave pandemia causada pelo novo coronavírus
          (Sars-COV-2”), um profissional de saúde pode prescrever uma medida de
          isolamento social para uma pessoa caso ela apresente sintomas ou
          esteja suspeita de estar infectada. Com isso o paciente deve ficar em
          quarentena, inicialmente de 14 dias onde não poderá sair de casa. Ele
          será liberado somente após 14 dias, caso os sintomas passem ou antes
          se os exames testarem negativo. Testando positivo e ele ainda
          continuando com os sintomas ele continua na quarentena.
          <br /> <br />O problema que surge é:
          <p>
            <b>
              Como acolher, monitorar e verificar se essas pessoas estão
              obedecendo a quarentena ?
            </b>
          </p>
          <br /> <br />A solução surge de uma parceria entre o{" "}
          <b>LAIS(Laboratório de Inovação em Saúde)</b> e uma startup, o{" "}
          <b>FACEPONTO</b>.
          <br /> <br />
          O FACEPONTO , em conjunto com o OrientaCorona, acolhe, acompanha e
          monitora, através de reconhecimento facial e geolocalização a posição
          das pessoas em quarentena. O paciente monitorado ficará informando a
          evolução de seu quadro, comprovando, em momentos aleatórios do dia,
          que esta em casa, caso ele nao responda ou não esteja em casa o
          sistema notifica as autoridade competentes. Além disso, todos esses
          dados são enviados para a base de dados do LAIS que faz integração com
          diversos outros sistemas da SESAP e Ministério da Saúde.
          <br /> <br />O aplicativo tem capacidade de monitorar toda a demanda
          do estado e pode expandir a sua infraestrutura de maneira a ganhar
          escala e atender inclusive outros estados.
        </div>
      </div>
    </section>
  );
};

export default Introduce;
