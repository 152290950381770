import React from "react";

import "../../styles/features.scss";

const Featuress = () => {
  return (
    <section className="features" id="funcionalidades">
      <div className="features__row">
        <div>
          <h1>Funcionalidades do aplicativo</h1>

          <ul>
            <li>
              <span className="features__circle features__bold">1</span>
              <span>Sistema de Reconhecimento Facial </span>
            </li>
            <li>
              <span className="features__circle features__bold">2</span>
              <span>Geolocalização via GPS</span>
            </li>
            <li>
              <span className="features__circle features__bold">3</span>
              <span>
                APIs de comunicação para integração
                <br /> com Salas de Situação
              </span>
            </li>
            <li>
              <span className="features__circle features__bold">4</span>
              <span>Check-ins em horarios aleatórios do dia</span>
            </li>
            <li>
              <span className="features__circle features__bold">5</span>
              <span>Emissão de Alertas e Notificações</span>
            </li>
            <li>
              <span className="features__circle features__bold">6</span>
              <span>
                Acompanhamento do Quadro Sintomático
                <br /> do Usuário
              </span>
            </li>
          </ul>
        </div>
        <div className="features__column">
          <div>
            <img src="assets/faceponto_quarentena_app.jpg" alt="Faceponto" />
          </div>
          <div className="features__video">
            <iframe
              id="ytplayer"
              title="faceponto_feature"
              type="text/html"
              width="400"
              height="260"
              src="https://www.youtube.com/embed/Gsu-TFjMF_s?controls=0&color=white"
              frameborder="0"
              allowfullscreen
            />
          </div>
        </div>
      </div>
      <div className="features__desktop">
        <h1>Também disponível a dashboard no navegador</h1>
        <div className="features__desktop__img">
          <img src="assets/faceponto_dashboard.png" alt="desktop" />
        </div>
      </div>
    </section>
  );
};

export default Featuress;
