import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Main from './components/main';
import Register from './components/register';
import ErrorPage from './components/errorPage';

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Main} />
        <Route exact path="/registro" component={Register} />
        <Route component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
