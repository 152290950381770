import React, { useState } from "react";
import { Link } from "react-router-dom";
import MaskedInput from "react-text-mask";
import DatePicker from "react-datepicker";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";

import "../../styles/register.scss";
import "react-datepicker/dist/react-datepicker.css";

import Footer from "../footer";

const Register = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [onSent, setOnSent] = useState(false);
  const [success, setSuccess] = useState(false);

  const AUTOREGISTER_ENDPOINT =
    "https://faceponto.com.br/paciente/autocadastro";
  const token = "n3vhyEeQJUMTF8YQte8RVzcC65RybxeZem3GABGt";
  const id = "108";

  //const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const phoneRegex = /\(\d{2,}\) \d{4,}-\d{4}/g;
  //const cpfRegex = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/;
  const cpfRegex = /[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}/g;
  const cepRegex = /[0-9]{5}-[\d]{3}/g;

  const cepMask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/];

  const cpfMask = [
    /[0-9]/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ];

  const phoneMask = [
    "(",
    /[1-9]/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      cpf: "",
      cep: "",
      number: "",
      phone: "",
      genre: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(50, "O limite de caracteres foi excedido.")
        .required("Este campo é obrigatório ser preenchido."),
      email: Yup.string()
        .email()
        .required("Este campo é obrigatório ser preenchido."),
      cpf: Yup.string()
        .matches(cpfRegex, "Este CPF não é válido.")
        .required("Este campo é obrigatório ser preenchido."),
      cep: Yup.string()
        .matches(cepRegex, "Não é um CEP válido.")
        .required("Este campo é obrigatório ser preenchido."),
      number: Yup.number(),
      phone: Yup.string()
        .matches(phoneRegex, "erro")
        .required("Este campo é obrigatório ser preenchido."),
      genre: Yup.string(),
    }),
    onSubmit: (values) => {
      setOnSent(true);

      const birthday = startDate.toISOString().slice(0, 10);
      //const person = {...values, birthday, token, id};

      const person = {
        cep: values.cep,
        sexo: values.genre,
        email: values.email,
        nome: values.name,
        cpf: values.cpf,
        data_nascimento: birthday,
        numero: values.number,
        telefone: values.phone,
        id_usuario: id,
        token: token,
      };

      //console.log(JSON.stringify(person, null, 2));
      axios
        .post(AUTOREGISTER_ENDPOINT, person)
        .then((res) => {
          JSON.stringify(person, null, 2);
          // console.log(res);
          // console.log(JSON.stringify(person, null, 2));
          setOnSent(false);
          setSuccess(true);
        })
        .catch(console.error);
    },
  });

  const { errors, touched } = formik;

  return (
    <div className="register__container">
      <div className={`modal-sending ${onSent && `modal-sending--open`}`}>
        <div className="modal-sending__content">
          <div className="modal-sending__icon"></div>
          <h3>Enviando...</h3>
        </div>
      </div>
      <div className={`when-sent ${success && `when-sent--open`}`}>
        <div className="when-sent__content">
          <div className="when-sent__button">
            <Link to="/">
              <h3>Voltar ao Inicio</h3>
            </Link>
          </div>

          <h1>Usuário cadastrado!</h1>

          <h3>
            Sua senha foi enviado a sua caixa de entrada!
            <br />
            Não esqueça de passar na sua lojas de app pelo seu
            <br /> smartphone e instalar o <strong>Faceponto</strong>.Nele você
            poderá <br />
            também ter acesso a nossa plataforma.
          </h3>
          <div className="when-sent__download">
            <a href="https://play.google.com/store/apps/details?id=incubatech.faceponto&hl=pt_BR">
              <img src="assets/android.svg" alt="Android" width="64" />
            </a>
            <a href="https://apps.apple.com/us/app/faceponto/id1407467891">
              <img src="assets/apple.svg" alt="iPhone" width="64" />
            </a>
          </div>
          <Link to="/">
            <img
              src="assets/faceponto_logo.svg"
              alt="Faceponto Logo"
              width="200"
            />
          </Link>
        </div>
      </div>
      <header>
        <Link to="/">
          <img
            src="assets/faceponto_logo.svg"
            alt="Faceponto Logo"
            width="200"
          />
        </Link>

        <h1 className="register__title">Autocadastro</h1>
      </header>

      <div className="register">
        <form onSubmit={formik.handleSubmit}>
          <div className={`register__form__divider__column`}>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Nome completo *"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              className={`register__form ${
                errors.name && touched.name && `register__form--error`
              }`}
            />

            <input
              type="email"
              name="email"
              id="email"
              placeholder="E-mail *"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className={`register__form ${
                errors.email && touched.email && `register__form--error`
              }`}
            />
          </div>

          <div className="register__form__divider">
            <MaskedInput
              type="text"
              mask={cpfMask}
              name="cpf"
              id="cpf"
              placeholder="CPF *"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.cpf}
              className={`register__form__divider__column ${
                errors.cpf && touched.cpf && `register__form--error`
              }`}
            />

            <DatePicker
              name="birthday"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showPopperArrow={false}
              dateFormat="dd/MM/yyyy"
              placeholderText="Data de Nascimento"
            />
          </div>

          <div className="register__form__divider">
            <MaskedInput
              mask={cepMask}
              type="text"
              name="cep"
              placeholder="CEP *"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.cep}
              className={`register__form__divider__column ${
                errors.cep && touched.cep && `register__form--error`
              }`}
            />

            <input
              type="text"
              name="number"
              placeholder="Número da casa"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.number}
              className={`register__form__divider__column ${
                errors.number && touched.number && `register__form--error`
              }`}
            />
          </div>

          <div className="register__form__divider">
            <MaskedInput
              type="tel"
              mask={phoneMask}
              name="phone"
              id="phone"
              placeholder="Telefone: (00) 00000 0000 *"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              className={`register__form__divider__column ${
                errors.phone && touched.phone && `register__form--error`
              }`}
            />

            <select
              name="genre"
              id="genre-options"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.genre}
              className={`register__form__divider__column ${
                errors.genre && touched.genre && `register__form--error`
              }`}
            >
              <option value="">Selecione seu sexo</option>
              <option value="1">Masculino</option>
              <option value="2">Feminino</option>
            </select>
          </div>
          <br />
          <button type="submit" className="btn">
            Registrar
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
