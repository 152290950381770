import React from "react";

import "../../styles/download.scss";

const Download = () => {
  return (
    <section className="download" id="baixar">
      <h2>Disponíveis nas plataformas</h2>
      <div>
        <a href="https://play.google.com/store/apps/details?id=incubatech.faceponto&hl=pt_BR">
          <img src="assets/android.svg" alt="Android" />
        </a>
        <a href="https://apps.apple.com/us/app/faceponto/id1407467891">
          <img src="assets/apple.svg" alt="iPhone" />
        </a>
      </div>
    </section>
  );
};

export default Download;
