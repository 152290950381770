import React from 'react';
import {Link} from 'react-router-dom';

import Menu from './menu';
import Footer from './footer';

const ErrorPage = () => {
  return (
    <div className="error-page__container">
    <Menu />
      <section className="error-page">
        <h1>Erro 404</h1>
        <h2>Página não encontrada.</h2>
        <Link to="/"><h3>Voltar para o inicio</h3></Link>
      </section>
    <Footer />
    </div>
  );
}

export default ErrorPage;
