import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Menu = () => {
  
  const [showMenu, isShowMenu] = useState(false);

  return (
    <>
      <div className="header__nav">

        <div className="menu-mobile">
          <button
            onClick={() => isShowMenu(!showMenu)}
            className="menu-mobile__button"
            >
              {showMenu ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
          </button>        
          <ul className={`menu-mobile__nav ${showMenu && `menu-mobile__nav--open`}`}>
            <li>
              <a href="/">
                <img src="assets/faceponto_logo.svg" alt="Faceponto Logo" />
              </a>
            </li>

            <li>
              <AnchorLink href="#motivo">Motivo</AnchorLink>
            </li>
            
            <li>
              <AnchorLink href="#situacao">Situação</AnchorLink>
            </li>
            
            <li>
              <AnchorLink  href="#funcionalidades">Funcionalidades</AnchorLink>
            </li>
            
            <li>
              <AnchorLink  href="#baixar">Aonde ter acesso</AnchorLink>
            </li>

            <li className="menu__login">
              <a href="https://faceponto.com.br/login/auth">Login</a>
            </li>

            <li className="menu__login">
              <Link to="/registro">
                Registrar
              </Link>
            </li>

          </ul>
        </div>
      </div>
      
      <div className="menu">
        <ul>
          <li>
            <a href="/">
              <img src="assets/faceponto_logo.svg" alt="Faceponto Logo" />
            </a>
          </li>

          <li>
            <AnchorLink href="#motivo">Motivo</AnchorLink>
          </li>
          
          <li>
            <AnchorLink href="#situacao">Situação</AnchorLink>
          </li>
          
          <li>
            <AnchorLink href="#funcionalidades">Funcionalidades</AnchorLink>
          </li>
          
          <li>
            <AnchorLink href="#baixar">Aonde ter acesso</AnchorLink>
          </li>
          <li>
            <a href="https://incubatech.faceponto.com.br/privacidade/">Privacidade/Termos</a>
          </li>

          <li className="menu__login" style={{flex: 1}}>
            <a href="https://faceponto.com.br/login/auth" >Login</a>
          </li>

          <li className="menu__login">
            <Link to="/registro">
              Registrar
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Menu;
