import React from 'react';

import Layout from './layout';
import Introduce from './sections/introduce';
import Situation from './sections/situation';
import Features from './sections/features';
import Download from './sections/download';

const Main = () => {
  return (
    <Layout>
      <Introduce />
      <Situation />
      <Features />
      <Download />
    </Layout>
  );
}

export default Main;
