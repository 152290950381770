import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__row">
        <div className="footer__description">
          <img src="assets/faceponto_logo.svg" alt="Faceponto" />
          <p>O Faceponto é uma startup de trabalho
          e ponto digital inteligente por georreferenciamento.
          E está na luta contra COVID-19.</p>

          <div className="footer__social">
            <a href="https://www.facebook.com/faceponto/">
              <img src="assets/facebook.svg" alt="Facebook Faceponto" />
            </a>

            <a href="https://www.instagram.com/faceponto/">
              <img src="assets/instagram.svg" alt="Instagram Faceponto" />
            </a>

            <a href="https://www.linkedin.com/company/incubatechbr/">
              <img src="assets/linkedin.svg" alt="LinkedIn Faceponto" />
            </a>

          </div>
        </div>

        <div className="footer__partners">
          <a href="https://covid.lais.ufrn.br/"><img src="assets/lais.png" alt="O Laboratório de Inovação Tecnológica em Saúde (LAIS)" /></a>
          <a href="http://www.saude.rn.gov.br/"><img src="assets/sesap.png" alt="Secretaria de Saúde Pública do Rio Grande do Norte (SESAP)" /></a>
        </div>
      </div>

      <div className="footer__links">
        <ul>

          <li>
            <a href="https://faceponto.com.br/login/auth">
              Login
            </a>
          </li>

          <li>
            <a href="https://incubatech.faceponto.com.br/privacidade">
              Termos de Privacidade
            </a>
          </li>

        </ul>
      </div>

      <div className="footer__bottom">
        <span>
          Faceponto | Incubatech - 2018 - Todos os direitos reservados
        </span>
        <span>
          CNPJ: 28.675.682/0001­-51
        </span>
      </div>
    </footer>
  );
}

export default Footer;
