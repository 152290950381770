import React, { useState, useEffect } from 'react';
import axios from 'axios';

import '../../styles/situation.scss';

const Situation = () => {

  const [showing, isShowing] = useState(false);

  const [data, setData] = useState({
    recovered: 0,
    cases: 0,
    deaths: 0,
  });

  useEffect(() => {
    axios.get('https://coronavirus-19-api.herokuapp.com/countries/brazil')
      .then((res) => {
        setData({
          recovered: res.data.recovered,
          cases: res.data.cases,
          deaths: res.data.deaths,
        })
      })
      .catch(console.log);
  }, []);

  return (
    <section className="situation" id="situacao">
      <h1>
        Atual situação no Brasil
      </h1>
      <ul>
        <li>
          <span>
            Curados
          </span>
          <span className="situation__green">
            {data.recovered}
          </span>
        </li>

        <li>
          <span>
            Infectados
          </span>
          <span className="situation__orange">
            {data.cases}
          </span>
        </li>

        <li>
          <span>
            Mortes
          </span>
          <span className="situation__red">
            {data.deaths}
          </span>
        </li>
      </ul>
      <div className="situation__prevention" onClick={
        () => isShowing(!showing)
      }>
        <span>Como se prevenir</span>
        <span>▼</span>
      </div>
      <div className={`situation__prevention__content ${showing && `situation__prevention__content--show`}`}>
        <h1>🖊 5 dicas</h1>
        <span>Ajude a combater o coronavírus, siga os passos a seguir</span>
        <br />
        <br />
        <ul>
          <li><p>Lave as mãos com frequência</p></li>
          <li><p>Use o cotovelo ou um pano para cobrir a tosse</p></li>
          <li><p>Não toque no rosto</p></li>
          <li><p>Mantenha uma distância segura de interações</p></li>
          <li><p><strong>NÃO</strong> saia de casa</p></li>
        </ul>
      </div>

    </section>
  );
}

export default Situation;
