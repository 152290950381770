import React from 'react';

import Menu from './menu';

const Header = () => {

  return (
    <header className="header">

      <Menu />

      <div className="header__col">
        <h3 className="header__description">
          Sistema de Monitoramento de pessoas em Quarentena
        </h3>
      </div>
      <div className="header__partners">
        <a href="https://covid.lais.ufrn.br/"><img src="assets/lais.png" alt="O Laboratório de Inovação Tecnológica em Saúde (LAIS)" /></a>
        <a href="http://www.saude.rn.gov.br/"><img src="assets/sesap.png" alt="Secretaria de Saúde Pública do Rio Grande do Norte (SESAP)" /></a>
      </div>
    </header>
  );
}

export default Header;

